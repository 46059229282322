/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import toast from "react-hot-toast";
import requestTypeEnum from "../../constants/enums/request-type-enum";
import targetTypeEnum from "../../constants/enums/target-type-enum";
import URLConstants from "../../constants/url-constants";
import config from "../../helpers/config-helper";
import { IObservableInitialState } from "../../models/ICommon";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { IDownloadsVM } from "../../models/response/IDownloadsResponse";
import { IUserLogsVM } from "../../models/response/IUserLogsResponse";
import { ICommonState } from "../../models/state/ICommonState";
import { formatMessage } from "../../translations/format-message";
import { initialState as allDownloadsInitialState } from "../initialState/get-all-downloads-state";
import * as baseService from "../service/base-service";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_WITH_MILLISECONDS;

export class DownloadsStore implements ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  downloadsList: IDownloadsVM = allDownloadsInitialState;
  getAllDownloadsState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      downloadsList: observable,
      getAllDownloadsState: observable,

      GetDownloadsListService: action,
      resetStore: action,
      reset: action,

      allDownloadsList: computed,
    });
  }

  /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetDownloadsListService = (pageNumber: number, pageSize: number, tenantId: number) => {
    this.getAllDownloadsState.inProgress = true;
    const url =
      URLConstants.UserLogsList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize + 
      "&tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IDownloadsVM>>) => {
        if (response.data.Error) {
          this.getAllDownloadsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.downloadsList = response.data.Data;
          this.getAllDownloadsState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllDownloadsState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allDownloadsList(): any[] {
    if (this.downloadsList && this.downloadsList?.Downloads?.length > 0) {
      return this.downloadsList?.Downloads.map((downloads: any) => {
        return {
          DLRequestId: downloads?.DLRequestId,
          TenantId: downloads?.TenantId,
          Status: downloads?.Status,
          DownloadLink: downloads?.DownloadLink,
          RequestStartTime: downloads?.RequestStartTime,
          RequestEndTime: downloads?.RequestEndTime,
          FileName: downloads?.FileName,
          CreatedDate: downloads?.CreatedDate,
        };
      });
    }
    return [];
  }

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.getAllDownloadsState = { ...this.initialStateValue };
  };
  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.getAllDownloadsState = { ...this.initialStateValue };
  };
}

export default new DownloadsStore();
