/**
 * This functional component "Add / Edit Video" provides form to add new Video Media or update existing Media .
 * Created Date:10-May-2024
 */
import { Button, Modal } from "react-bootstrap";
import "./edit-video-media-form.css";
import { FormattedMessage } from "react-intl";
import { Formik, Field, Form } from "formik";
import { observer } from "mobx-react";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import LoaderButton from "../../../../shared-components/button/loader-button";
import Label from "../../../../shared-components/label/label";
import {
  UpdateVideoValidateSchema,
  UploadVideoMediaValidateSchema,
} from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { useStore } from "../../../../contexts/store-provider";
import { useEffect, useState } from "react";
import FormikFormVideoFileInput from "../../../../shared-components/FormikFormHandlers/FormikFormVideoFileInput";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  selectedFolderType:number;
  onModalClosed: () => void;
  initialValues: any;
  isLoading: boolean;
}

const AddUpdateVideoMediaForm = (props: IProps) => {
  const { preferencesStore } = useStore();
  const { getMediaAccess } = preferencesStore;
  const { initialValues, submitHandler, isLoading, onModalClosed } = props;

  const [data, setData] = useState({ ...initialValues });
  // const [showHidePassword, setShowHidePassword] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: any = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const StepOne = (props1: any) => {
    const handleSubmit = (values: any) => {
      props1.next({ ...values });
    };
    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={UpdateVideoValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, isValid }) => {
          return (
            <Form id="formBody">
              {props.id === -1 && (
                <div className="pt-3">
                  <div className="progress step-progress">
                    <div className="progress-bar" role="progressbar"></div>
                  </div>
                  <div className="step-container d-flex justify-content-between">
                    <div className="step-circle active">
                      1{" "}
                      <span className="step-detail">
                        <FormattedMessage id="video_details" />
                      </span>
                    </div>
                    <div className="step-circle">
                      2{" "}
                      <span className="step-detail">
                        <FormattedMessage id="upload_video" />
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="video_name"
                    />
                    <Field
                      data-testid="VideoName"
                      className="form-control"
                      name="VideoName"
                      placeholder="video_name"
                      type="text"
                      disabled={props.id > 0 ? true : false}
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      invalidcharacter={[" "]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>

                
                {(props.selectedFolderType == 3 ||
                  props.selectedFolderType == 4 ||
                  props.selectedFolderType == 5) && (
                    <div className="row pe-0">
                      <div className="col-md-6 col-6">
                        <div className="form-group formSet">
                          <Label
                            className="label-style"
                            required={true}
                            label="media_height"
                          />
                          <Field
                            data-testid="media_height"
                            className="form-control"
                            name="Height"
                            placeholder="media_height"
                            type="number"
                            as={FormikFormInput}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-6">
                        <div className="form-group formSet">
                          <Label
                            className="label-style"
                            required={true}
                            label="media_width"
                          />
                          <Field
                            data-testid="media_height"
                            className="form-control"
                            name="Width"
                            placeholder="media_width"
                            type="number"
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={false}
                      label="comment"
                    />
                    <Field
                      rows={props.id == -1 ? 3 : 2}
                      id="Comment"
                      className="form-control"
                      name="Comment"
                      placeholder="comment"
                      type="text"
                      as={FormikFormTextArea}
                    />
                  </div>
                </div>
              </div>

              <div className="pb-3 mt-3 d-flex justify-content-center">
                {props.id > 0 ? (
                  <LoaderButton
                    type={isValid ? "submit" : "button"}
                    id="SaveTenant"
                    onClick={
                      isValid
                        ? () => {
                            props1.next(values, true);
                          }
                        : undefined
                    }
                    isLoading={props.isLoading}
                    text="submit"
                    className="ms-2"
                  />
                ) : (
                  <LoaderButton
                    type="submit"
                    id="SaveTenant"
                    isLoading={props.isLoading}
                    text="next"
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const StepTwo = (props2: any) => {
    const [fileData, setFileData] = useState<any>();
    const [videoExtension, setVideoExtension] = useState<any>();
    const handleSubmit = (values: any) => {
      let data = { ...values, File: fileData, Extension: videoExtension };
      props.submitHandler(data);
    };
    return (
      <Formik
        initialValues={props2.data}
        onSubmit={handleSubmit}
        validationSchema={UploadVideoMediaValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form id="formBody">
                  <div className="modal-body">
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div
                    className={`progress-bar ${
                      props.id === -1 ? "w-50" : "w-100"
                    }`}
                    role="progressbar"
                  ></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle completed">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="video_details" />
                    </span>
                  </div>
                  <div className="step-circle active">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="upload_video" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="form-group mb-1">
                    <Label
                      className="label-style"
                      required={true}
                      label="video_file"
                    />
                    <Field
                      className="form-control"
                      id="video_file"
                      name="VideoFile"
                      setFile={setFileData}
                      setExtension={setVideoExtension}
                      accept=".mp4"
                      as={FormikFormVideoFileInput}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-3 mt-4 d-flex justify-content-center">
                <Button
                  data-testid="back"
                  variant="light-secondary"
                  onClick={() => {
                    props2.prev(values);
                  }}
                  disabled={props.isLoading}
                  className="mr-3"
                >
                  <FormattedMessage id="back" />
                </Button>

                <LoaderButton
                  type="submit"
                  id="SaveTenant"
                  className="ms-2"
                  isLoading={props.isLoading}
                  text="submit"
                />
              </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const steps = [
    <StepOne
      next={handleNextStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
    <StepTwo
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
  ];

  return (
    <div>
      {isLoading && <FormLoader loading={isLoading} />}
      {steps[currentStep]}
    </div>
  );
};
export default observer(AddUpdateVideoMediaForm);
