/**
 * This functional component "Add Media" provides form to add new Media or update existing Media .
 * Created Date:23-April-2024
 */
import { observer } from "mobx-react";
import "./add-edit-integrator-form.css";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { useStore } from "../../../contexts/store-provider";
import { useEffect, useState } from "react";
import FormikFormImageFileInput from "../../../shared-components/FormikFormHandlers/FormikFormImageFileInput";
import FormikFormOnChangeSelect from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelect";
import { IOption } from "../../../models/ICommon";
import toast from "react-hot-toast";
import { AddIntegratorValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { formatMessage } from "../../../translations/format-message";
import Select from "react-dropdown-select";

interface IProps {
  id: number;
  allAvailableParkingLots: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  initialValues: any;
  isDataLoading: boolean;
  isLoading: boolean;
}
const AddUpdateIntegratorForm = (props: IProps) => {
  const { preferencesStore } = useStore();
  const { getMediaAccess } = preferencesStore;
  const { initialValues, submitHandler, isLoading, onModalClosed } = props;
  const [assignedParkingLot, setAssignedParkingLot] = useState<any[]>([]);
  const [data, setData] = useState({ ...initialValues });
  // const [showHidePassword, setShowHidePassword] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  useEffect(() => {
    if (props.id > 0) {
      let arr: any[] = [];
      props.initialValues.IntegratorParkingLotVMs?.map((data: any) => {
        arr.push({
          Id: data?.ParkingLot?.PlId,
          PKey: data?.Id,
          Name: data?.ParkingLot?.PlName,
        });
      });
      setAssignedParkingLot(arr);
    }
  }, [props.initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: any = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    if (Number(newData.AssignedParkingLot) > -1) {
      newData.AssignedParkingLot = -1;
    }
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const StepOne = (props1: any) => {
    const handleSubmit = (values: any) => {
      // if(props.Id > 0){
      //     props.submitHandler(values);
      //     // props1.next(values, props.Id === -1 ? false : true);
      // }else{

      props1.next({ ...values });
      // }
    };
    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={AddIntegratorValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, isValid }) => {
          return (
            <Form id="formBody">
              {(props.isDataLoading || props.isLoading) && (
                <FormLoader loading={props.isDataLoading || props.isLoading} />
              )}
              <div className="pt-3">
                <div className="progress step-progress">
                  <div className="progress-bar" role="progressbar"></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle active">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="integrator_details" />
                    </span>
                  </div>
                  <div className="step-circle">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="parking_threshold" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="integrator_id"
                  />
                  <div className="col-sm-12">
                    <Field
                      data-testid="Integratorid"
                      className="form-control"
                      name="IntegratorId"
                      disabled={props.id > 0 ? true : false}
                      type="text"
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      placeholder="integrator_id"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="integrator_name"
                  />
                  <div className="col-sm-12">
                    <Field
                      data-testid="Integratorname"
                      className="form-control"
                      name="IntegratorName"
                      type="text"
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      placeholder="integrator_name"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={false}
                    label="comment"
                  />
                  <div className="col-sm-12">
                    <Field
                      rows={props.id == -1 ? 3 : 2}
                      id="Comment"
                      className="form-control"
                      name="Comment"
                      placeholder="comment"
                      type="text"
                      as={FormikFormTextArea}
                    />
                  </div>
                </div>
              </div>

              <div className="pb-3 mt-3 d-flex justify-content-center">
                {/* {props.id > 0 ? ( */}
                <LoaderButton
                  type={"submit"}
                  id="SaveTenant"
                  isLoading={props.isLoading}
                  text="next"
                />
                {/* <LoaderButton
                    type={isValid ? "submit" : "button"}
                    id="SaveTenant"
                    onClick={
                      isValid
                        ? () => {
                            props1.next(values, true);
                          }
                        : undefined
                    }
                    isLoading={props.isLoading}
                    text="submit"
                    className="ms-2"
                  /> */}
                {/* ) : ( */}

                {/* )} */}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const StepTwo = (props2: any) => {
    const [fileData, setFileData] = useState<any>("");
    const handleSubmit = (values: any) => {
      props.submitHandler({
        ...values,
        parkingLots: assignedParkingLot,
      });
      // props2.next(values, props.Id === -1 ? false : true);
    };

    const addToTable = () => {
      const data = props.allAvailableParkingLots?.find(
        (lot: any) =>  (lot.id > -1) && (lot.id == fileData)
      );

      const alreadyAdded = assignedParkingLot?.some(
        (item) => item.Id === data?.id
      );
      if(data?.id > -1){
        if (assignedParkingLot?.length <= 7) {
          if (alreadyAdded) {
            toast.error(formatMessage("parking_already_assigned"));
          } else {
            if (data) {
              setAssignedParkingLot([
                ...assignedParkingLot,
                { ...{ Id: data.id, Name: data.data } },
              ]);
            }
          }
        } else {
          toast.error(formatMessage("assign_limit_reached"));
        }
      };
      }

    const deleteObj = (id: number) => {
      let data = assignedParkingLot?.filter((item) => item.Id !== id);
      if (data) {
        setAssignedParkingLot(data);
      }
    };

    return (
      <Formik
        initialValues={props2.data}
        onSubmit={handleSubmit}
        // validationSchema={UploadImageMediaValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form id="formBody">
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div
                    className={`progress-bar ${
                      props.id === -1 ? "w-50" : "w-100"
                    }`}
                    role="progressbar"
                  ></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle completed">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="integrator_details" />
                    </span>
                  </div>
                  <div className="step-circle active">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="parking_threshold" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <div className="form-group mb-1">
                    <Label
                      className="label-style"
                      required={true}
                      label="select_parking"
                    />
                    {/* <Field
                      className="form-control"
                      id="image_file"
                      name="AssignedParkingLot"
                      setState={setFileData}
                      options={props.allAvailableParkingLots}
                      as={FormikFormOnChangeSelect}
                    /> */}
                    <Select
                      options={props.allAvailableParkingLots?.filter(obj => !assignedParkingLot?.map(item => item?.Id)?.includes(obj.id))}
                      values={[]}
                      loading={props.isLoading}
                      className="form-control"
                      noDataLabel={formatMessage("no_pl_found")}
                      searchable={true}
                      name="select"
                      onChange={(value) => setFileData(value[0]?.id)}
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group mb-1">
                    <Button
                      data-testid="back"
                      // variant="light-secondary"
                      onClick={() => {
                        addToTable();
                      }}
                      disabled={props.isLoading}
                      className="btn btn-primary mt-4"
                    >
                      <FormattedMessage id="add" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="tableDesign border row-eq-height integrator-table">
                <table className="table table-bordered border">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="serial_no" />
                      </th>
                      <th>
                        <FormattedMessage id="parking_lot_name" />
                      </th>
                      <th>
                        <FormattedMessage id="actions" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedParkingLot?.map((lot: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{lot.Name}</td>
                          <td>
                            <svg
                              id="Layer_2"
                              fill="#0091ff"
                              width="18"
                              height="18"
                              onClick={() => {
                                deleteObj(lot.Id);
                              }}
                              data-name="Layer 2"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20.09 20.66"
                            >
                              <g id="_レイヤー_3" data-name="レイヤー 3">
                                <g>
                                  <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                                  <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                                  <rect
                                    x="11.68"
                                    y="6.8"
                                    width="1.16"
                                    height="9.8"
                                  />
                                </g>
                              </g>
                              <title>{formatMessage("delete")}</title>
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pb-3 mt-4 d-flex justify-content-center">
                <Button
                  data-testid="back"
                  variant="light-secondary"
                  onClick={() => {
                    props2.prev(values);
                  }}
                  disabled={props.isLoading}
                  className="mr-3"
                >
                  <FormattedMessage id="back" />
                </Button>

                <LoaderButton
                  type="submit"
                  id="SaveTenant"
                  disabled={assignedParkingLot?.length > 0 ? false : true}
                  className="ms-2"
                  isLoading={props.isLoading}
                  text={props.id > 0 ? "Update" : "submit"}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const steps = [
    <StepOne
      next={handleNextStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
    <StepTwo
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
  ];

  return (
    <div>
      {isLoading && <FormLoader loading={isLoading} />}
      {steps[currentStep]}
    </div>
  );
};
export default observer(AddUpdateIntegratorForm);
