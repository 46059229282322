import { jwtDecode } from "jwt-decode";

export const getUserType = () => localStorage.getItem("hips_userType");

export const getToken = () => localStorage.getItem("hips_token");

export const getRefreshToken = () => localStorage.getItem("hips_refreshToken");

export const getUserName = () => localStorage.getItem("hips_userName");

export const getUserId = () => localStorage.getItem("hips_userId");

export const getParamType = () => localStorage.getItem("hips_paramType");

export const getTimeZone = () => localStorage.getItem("hips_timezoneId");

export const getdebuglogsfilter = () => localStorage.getItem("hips_debugLogFilter");

export const getTenantIdFromLocalStorage = () =>
  localStorage.getItem("hips_access_tenant_id");

export const getTokenFromLocalStorage = () => {
  let token = localStorage.getItem("hips_token");
  return token ? token : "";
};

export const getRoleNameFromLocalStorage = () => {
  let roleName = localStorage.getItem("hips_role_name");
  return roleName ? roleName : "";
};

export const getTenantIdFromToken = () => {
  let token = localStorage.getItem("hips_token");
  let tenantId:any = 0;
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    tenantId = userToken.tenantId;
  }
  return parseInt(tenantId);
}

export const getOrgIdFromToken = () => {
  let token = localStorage.getItem("hips_token");
  let orgId:any = "";
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    orgId = userToken.organizationId;
  }
  return orgId;
}

export const getUserIdFromToken = () => {
  let token = localStorage.getItem("hips_token");
  let userId:any = 0;
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    userId = userToken.userId;
  }
  return parseInt(userId);
}

export const getUserEmailFromToken = () => {
  let token = localStorage.getItem("hips_token");
  let userEmail:string = "";
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    userEmail = userToken.userEmail;
  }
  return userEmail;
}

export const getTenantAccessFromLocalStorage = () :any => {
  let tenantId = localStorage.getItem("hips_access_tenant_id");
   return tenantId ? parseInt(tenantId) : -1;
}

export const getIsTenantAccessFromLocalStorage = () :any => {
  let isTenantAccess = localStorage.getItem("hips_is_access_tenant");
   return isTenantAccess ? JSON.parse(isTenantAccess) : false;
}

export const getUserTypeFromLocalStorage = () => {
  let userType = localStorage.getItem('hips_userType');
  return userType ? userType : "";
}

export const getCurrentLanguageFromLocalStorage = () => {
  let lang = localStorage.getItem("hips_language");
  return lang === 'en' ? 'en' : 'jp'
}