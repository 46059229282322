/*    
<summary>
  This functional component "AddUpdateDeviceForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/store-provider";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/format-message";
import FormikFormFileInput from "../../../shared-components/FormikFormHandlers/FormikFormFileInput";
import config from "../../../helpers/config-helper";
import moment from "moment";
import { selectCSVValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  initialValues: any;
  // groupList: any;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const appConfig = config();
const timeFormat = appConfig.REACT_APP_TIME_FORMAT;
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

const ChooseCSVFileForm = (props: IProps) => {
  let totalDeviceToBeAdded: number = 0;
  const { priceSchedulerStore, preferencesStore } = useStore();
  const { getPriceSchedulerAccess } = preferencesStore;
  // const { resetAddBulkDeviceState , resetAddDeviceCSV} = priceSchedulerStore;
  const [targetData, setTargetData] = useState("");
  const { initialValues, submitHandler, isLoading, modalClosed } = props;

  const getHeaderArray = (csvRecordsArr: any): string[] => {
    let headerArray = [];
    let headers = csvRecordsArr[0].split(",");
    for (let i = 0; i < headers.length; i++) {
      headerArray.push(headers[i]);
    }
    return headerArray;
  };

  const headerInvalidError = (headersRow: any) => {
    var isInvalid: boolean = false;
    if (headersRow.length > 11) {
      isInvalid = true;
      return isInvalid;
    } else if (
      headersRow[0].trim() != "StartDate" ||
      headersRow[1].trim() != "EndDate" ||
      headersRow[2].trim() != "StartTime" ||
      headersRow[3].trim() != "EndTime" ||
      headersRow[4].trim() != "ScheduleType" ||
      headersRow[5].trim() != "Data1" ||
      headersRow[6].trim() != "Data2" ||
      headersRow[7].trim() != "Data3" ||
      headersRow[8].trim() != "Data4" ||
      headersRow[9].trim() != "Data5" ||
      headersRow[10].trim() != "Data6"
    ) {
      isInvalid = true;
      return isInvalid;
    }
    return isInvalid;
  };

  const handleFileUpload = (event: any) => {
    var files = event?.target?.result;
    setTargetData(files.toString());
  };

  const startFileDecode = () => {
    if (targetData) {
      let csvData: string = targetData;
      let csvRecordsArray = csvData?.split(/\r\n|\n/);
      let headersRow = getHeaderArray(csvRecordsArray);
      if (csvRecordsArray.length > 0) {
        totalDeviceToBeAdded = csvRecordsArray.length - 1;
      }
      let invalid = headerInvalidError(headersRow);
      if (invalid) {
        toast.error(formatMessage("invalid_headers"));
      } else {
        if (csvRecordsArray[1] != "") {
          let csv = CSVDataConditions(headersRow, csvRecordsArray);
          if (csv && csv?.length > 0) {
            return csv;
          }
        } else {
          toast.error(formatMessage("empty_csv"));
          return [];
        }
      }
    } else {
      toast.error(formatMessage("invalid_csv_format"));
    }
  };

  const CSVDataConditions = (headersRow: any, csvRecordsArray: any) => {
    let dataManipulated = getDataRecordsArrayFromCSVFile(
      csvRecordsArray,
      headersRow.length
    );
    return dataManipulated;
  };

  const getDataRecordsArrayFromCSVFile = (
    csvRecordsArray: any,
    headerLength: number
  ) => {
    var ImportDataArr = [];
    var totalColumns = 11;
    // csvRecordsArray.splice(csvRecordsArray.length - 1, 1);
    for (let i = 1; i <= csvRecordsArray?.length; i++) {
      let data = csvRecordsArray[i]?.split(",");
      if (data?.length > totalColumns) {
        data.splice(totalColumns);
      }
      if (data?.length == totalColumns) {
        var CSVdata = {
          StartDate: data[0]?.trim(),
          EndDate: data[1]?.trim(),
          StartTime: data[2]?.trim(),
          EndTime: data[3]?.trim(),
          ScheduleType: data[4]?.trim(),
          Data1: data[5],
          Data2: data[6],
          Data3: data[7],
          Data4: data[8],
          Data5: data[9],
          Data6: data[10],
          PlId: getPriceSchedulerAccess.plId,
        };
        ImportDataArr.push(CSVdata);
      }
    }
    return ImportDataArr;
  };

  // useEffect(() => {
  //   if (getAddBulkCount > 0) {
  //     modalClosed();
  //     resetAddDeviceCSV();
  //   }
  // }, [getAddBulkCount]);

  return (
    <>
      <div className="mx-auto">
        {isLoading && <FormLoader loading={props.isLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={selectCSVValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            let data = startFileDecode();

            values.AddSchedules = data;
            if (data && data.length > 0) {
              submitHandler(values);
            }
          }}
          render={({ values }) => {
            return (
              <Form id="formBody">
                <Modal.Body>
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="import_csv_file"
                    />
                    <Field
                      className="form-control"
                      id="ckf_file"
                      name="CSVFile"
                      handleFileUpload={handleFileUpload}
                      as={FormikFormFileInput}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    data-testid="Cancel"
                    variant="light-danger"
                    onClick={modalClosed}
                    disabled={isLoading}
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                  <LoaderButton
                    type="submit"
                    id="SaveDevice"
                    isLoading={isLoading}
                    text="submit"
                  />
                </Modal.Footer>
              </Form>
            );
          }}
        ></Formik>
      </div>
    </>
  );
};

export default observer(ChooseCSVFileForm);
