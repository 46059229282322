// This is Initial Data for AreaType 3 [2^3 Combinations = 8]
export const initialState: any = [
    { Id: -1, Area1Status: 2, Area2Status: 2, Area3Status: 2, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 2, Area2Status: 2, Area3Status: 0, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 2, Area2Status: 0, Area3Status: 2, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 2, Area2Status: 0, Area3Status: 0, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 0, Area2Status: 2, Area3Status: 2, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 0, Area2Status: 2, Area3Status: 0, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 0, Area2Status: 0, Area3Status: 2, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" },
    { Id: -1, Area1Status: 0, Area2Status: 0, Area3Status: 0, Area4Status: null, FolderId: -1, ImageId: -1, FolderName: "", ImageName: "", Comment: "" }
];
  