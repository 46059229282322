/*    
<summary>
   This component is Managing Media Management & performing CRUD operations on the Camera Data of Parking Lots.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:24-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./camera.css";
import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/store-provider";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import Pagination from "../../shared-components/Grid/Pagination";
import LoaderButton from "../../shared-components/button/loader-button";
import Grid from "../../shared-components/Grid/Grid";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import AddCameraPopupHOC from "./hoc/AddCameraPopupHOC";
import AddEditCamera from "./components/add-edit-camera";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import toast from "react-hot-toast";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import SlidingPanel from "react-sliding-side-panel";
import CameraDetails from "./camera-details";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { useHistory, useLocation } from "react-router-dom";
import { getComponentName } from "../../constants/constants";
import RoutesConstants from "../../constants/routes.constant";
import {
  getIsTenantAccessFromLocalStorage,
  getTenantAccessFromLocalStorage,
} from "../../helpers/localstorage-helper";
import { PermissionType } from "../../constants/enums/permission-type-enum";
import {
  isOperationPermittedRoleBase,
  showIconsRoleBase,
} from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import backIcon from "../../assets/Images/svg/back.svg";

const Camera = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const parkingLotName: any = location.state;
  const { preferencesStore, authStore, cameraStore } = useStore();
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    getParkingLotCameraAccess,
  } = preferencesStore;
  const {
    GetCameraListService,
    allCameraList,
    cameraList,
    deleteCameraState,
    addUpdateCameraState,
    resetGetCameraDetail,
    resetDeleteCameraState,
    resetAddUpdateCameraState,
    DeleteCameraService,
    resetParkingLotSlotList,
    GetAllCameraSlotsListService,
    inProgress,
  } = cameraStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const allCameras: Array<any> = allCameraList;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  useEffect(() => {
    if (openPanel) {
      setSidePanelOpen(true);
    }
  }, [openPanel]);

  /**
   * This function calls the "getAllCameras" store funtion that get Camera list from API
   */
  const callGetCameraListService = () => {
    GetCameraListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      getTenantAccessFromLocalStorage(),
      getParkingLotCameraAccess.plId
    );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetCameraListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: cameraList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetCameraListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * This function shows the add bramch pop up from where we can add new camera and update existing camera. we use id to identify
   * that we are adding or updating camera.
   * "Id == -1" : we are adding new camera
   * "Id != -1" : means we are updating existing camera
   */
  const openAddCameraHandler = (
    row: { CameraId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    event.stopPropagation();
    setSelectedId(row.CameraId);
    props.addCameraToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetCameraListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the camera by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteCameraService(selectedId, getTenantAccessFromLocalStorage());
  };

  /**
   * This function shows the delete camera confirmation popup component and sets the selected camera Id
   */
  const deleteClickHandler = (
    row: { CameraId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.CameraId);
    event.stopPropagation();
    resetGetCameraDetail();
    props.deletePopupToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetCameraListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateCameraState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateCameraState.success) {
      resetAddUpdateCameraState();
      callGetCameraListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateCameraState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteCameraState" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteCameraState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteCameraState();
      props.deletePopupToggleHandler();
      callGetCameraListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCameraState.success]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetCameraListService();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    // setOpenPanel(false);
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    setSidePanelOpen(false);
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isEdit",
        handler: openAddCameraHandler,
        icon: (
          <svg
            id="_レイヤー_3"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="レイヤー 3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
          >
            <path d="m26.08,1.63c1.26,0,2.28,1.02,2.28,2.28v22.16c0,1.26-1.03,2.28-2.28,2.28H3.92c-1.26,0-2.28-1.02-2.28-2.28V3.92c0-1.26,1.03-2.28,2.28-2.28h22.16m0-1.13H3.92C2.03.5.5,2.03.5,3.92v22.16c0,1.89,1.53,3.42,3.42,3.42h22.16c1.89,0,3.42-1.53,3.42-3.42V3.92c0-1.89-1.53-3.42-3.42-3.42h0Z" />
            <path d="m8.99,18.1l1.1,1.1,1.1,1.1-1.71.53-1.49.47.47-1.49.54-1.71m-.41-1.67l-.98,3.12-.98,3.12,3.12-.98,3.12-.98-2.14-2.14-2.14-2.14h0Z" />
            <polygon points="6.63 22.66 7.18 20.88 7.74 19.11 8.96 20.33 10.18 21.55 8.41 22.11 6.63 22.66" />
            <path d="m19.87,5.13l-10.82,10.82,4.29,4.29,10.82-10.82-4.29-4.29Zm-7.85,10.97l-.5-.5,7.86-7.86.5.5-7.86,7.86Z" />
          </svg>
        ),
        isDisable: true,
        title: "edit",
        varient: "primary",
        action: "edit",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="_レイヤー_3"
            data-name="レイヤー 3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            fill="#0091ff"
            width="21"
            height="21"
          >
            <path d="m26.08,1.63c1.26,0,2.28,1.02,2.28,2.28v22.16c0,1.26-1.03,2.28-2.28,2.28H3.92c-1.26,0-2.28-1.02-2.28-2.28V3.92c0-1.26,1.03-2.28,2.28-2.28h22.16m0-1.13H3.92C2.03.5.5,2.03.5,3.92v22.16c0,1.89,1.53,3.42,3.42,3.42h22.16c1.89,0,3.42-1.53,3.42-3.42V3.92c0-1.89-1.53-3.42-3.42-3.42h0Z" />
            <polygon points="15 9.35 8.1 9.35 8.1 7.92 13.58 7.92 13.58 6.56 15 6.56 15 9.35" />
            <polygon points="15 9.35 21.9 9.35 21.9 7.92 16.42 7.92 16.42 6.56 15 6.56 15 9.35" />
            <path d="m9.36,10.27v13.17h11.27v-13.17h-11.27Zm2.6,10.87h-.52v-9.01h.52v9.01Zm3.3,0h-.52v-9.01h.52v9.01Zm3.3,0h-.52v-9.01h.52v9.01Z" />
          </svg>
        ),
        isDisable: true,
        title: "delete",
        varient: "danger",
        action: "delete",
      },
    ],
    ModuleType.CameraManagement
  );

  /**
   * The column constant defines the column description for the camera grid (table)
   */
  const columns: any = [
    {
      dataField: "CameraId",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "CameraName",
      text: formatMessage("camera_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "MacAddress",
      text: formatMessage("mac_address"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "IpAddress",
      text: formatMessage("ip_address"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Comment",
      classes: "address-Tenant",
      text: formatMessage("comment"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons.length > 0 ? false : true,
    },
  ];

  return (
    <React.Fragment>
      {inProgress && <FormLoader loading={inProgress} />}

      {props.showAddCameraPopup && (
        <AddEditCamera
          id={selectedId}
          modalClosed={props.addCameraToggleHandler}
        />
      )}

      {props.showDeletePopup && (
        <DeletePopup
          title="delete_camera"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_camera_confirm"
          isLoading={deleteCameraState.inProgress}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="CameraManagement" />
              </h1>
              <ol className="breadcrumb">
                <li title={formatMessage("back")} className="back-icon pe-1">
                  <a
                    onClick={() =>
                      history.push(RoutesConstants.ParkingLotManagement)
                    }
                  >
                    {/* <img src={backIcon} /> */}
                    <svg
                      version="1.1"
                      height="14"
                      width="14"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 447.243 447.243"
                    >
                      <g>
                        <g>
                          <path
                            d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
			c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
			l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
			c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
			l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
			C449.654,211.494,437.806,195.059,420.361,192.229z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                <li className="breadcrumb-item">{parkingLotName}</li>
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="CameraManagement" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">
              {isOperationPermittedRoleBase(
                ModuleType.CameraManagement,
                "Add"
              ) && (
                <li title={formatMessage("add_camera")} className="ms-1">
                  <a
                    className="btn btn-secondary"
                    data-toggle="modal"
                    aria-disabled={true}
                    onClick={(event: any) =>
                      openAddCameraHandler({ CameraId: -1 }, event)
                    }
                    data-testid="modalbtn"
                    data-target="#addCamera"
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <FormattedMessage id="add_camera" />
                  </a>
                </li>
              )}

              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {allCameras.length > 0 ? (
        <div className={`${openPanel ? "panelOpen" : ""}`}>
          <div className="card changingPanel">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allCameras && (
                  <Grid
                    data={allCameras}
                    columns={columns}
                    keyField="CameraId"
                    loading={false}
                    rowClasses={rowClassFormat}
                    rowEvents={{ onClick: rowEvents }}
                  />
                )}
              </div>
            </div>
          </div>
          {allCameras.length > 0 ? (
            <div className="row no-gutters p-2 tableFooter">
              <div className="col-sm-6 pt-1">
                <FormattedMessage id="show" />
                <select
                  data-testid="pageSelect"
                  className="pageLimit"
                  onChange={onPageLimitChanged}
                  value={pageLimitInfo.pageLimit}
                >
                  {pageLimitOptions.map((op: any) => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <Pagination
                  isRefreshPage={false}
                  totalPages={pageInfo.totalPages}
                  pageLimit={pageLimitInfo.pageLimit}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                  setIsRefreshPage={setIsRefreshPage}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_camera" />
          </p>
          <p className="mb-3">{formatMessage("no_camera_message")}</p>
          {isOperationPermittedRoleBase(ModuleType.CameraManagement, "Add") && (
            <LoaderButton
              text="add_camera"
              onClick={(event: any) =>
                openAddCameraHandler({ CameraId: -1 }, event)
              }
            />
          )}
        </div>
      )}
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10">
              <h2 className="fw-bold mb-0 mt-1 py-2">
                {selectedRow.CameraName}
                {/* <FormattedMessage id={"config_details"} /> */}
              </h2>
            </div>
            <div className="col-lg-2">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <CameraDetails
              id={selectedRow?.CameraId}
              selectedData={selectedRow}
            />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default DeletePopupHOC(AddCameraPopupHOC(observer(Camera)));
