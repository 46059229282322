import { observer } from "mobx-react";
import "./add-edit-parking-lot-status-image-form.css";
import { IImageOption, IOption } from "../../../models/ICommon";
import { useStore } from "../../../contexts/store-provider";
import React, { useEffect, useState } from "react";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import { formatMessage } from "../../../translations/format-message";
import categoryEnum from "../../../constants/enums/category-enum";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import Label from "../../../shared-components/label/label";
import { Field, Form, Formik } from "formik";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import noMessageIcon from "../../../assets/Images/svg/018-empty.svg";
import { cloneDeep } from "lodash";
import LoaderButton from "../../../shared-components/button/loader-button";
import FormikFormOnChangeSelect from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelect";
import toast from "react-hot-toast";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import FormikFormOnChangeInput from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeInput";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onChangeInitialValues: (data: any, isUpdate:boolean) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  allTenantImagesOptions: IImageOption[];
  allImageOptions: IImageOption[];
  allFolderOptions: IOption[];
  allAvailableCategoryOptions: IOption[];
  allCategories: any[];
  initialValues: any;
}

const AddEditParkingLotStatusImageForm = (props: IProps) => {
  const { mediaStore } = useStore();
  const { GetAllImagesByFolderId, allAvailableImages, getAllImagesState } =
    mediaStore;
  const [statusArrayUpdate, setStatusArrayUpdate] = useState<Array<any>>([]);
  const [statusArrayAdd, setStatusArrayAdd] = useState<Array<any>>([]);

  const [isEditMode, setEditMode] = useState<boolean>(false);

  const [folderId, setFolderId] = useState<any>(-1);
  const [categoryId, setCategoryId] = useState<any>(-1);

  const [height, setHeight] = useState<number>(500);
  const [width, setWidth] = useState<number>(500);

  const [availableImageId, setAvailId] = useState<number>(-1);
  const [fullImageId, setFullId] = useState<number>(-1);
  const [closedImageId, setClosedId] = useState<number>(-1);
  const [crowdedImageId, setCrowdedId] = useState<number>(-1);

  useEffect(() => {
    if (folderId > 0) {        
      GetAllImagesByFolderId(
        folderId,
        getTenantAccessFromLocalStorage()
      );
      if(availableImageId > -1 || fullImageId > -1 || closedImageId > -1 || crowdedImageId > -1) {
        resetImageIds();
      }
    }
  }, [folderId]);

  useEffect(() => {
    if (props.initialValues.CategoryId > -1 && props.initialValues.FolderId > -1) {
        GetAllImagesByFolderId(
            props.initialValues?.FolderId,
            getTenantAccessFromLocalStorage()
          );
        setAvailId(props.initialValues?.AvailImage);
        setFullId(props.initialValues?.FullImage);
        setClosedId(props.initialValues?.ClosedImage);
        setCrowdedId(props.initialValues?.CrowdedImage);
        setFolderId(props.initialValues?.FolderId);
        setCategoryId(props.initialValues?.CategoryId);
      }
      setHeight(props.initialValues?.Height);
      setWidth(props.initialValues?.Width);
  }, [props.initialValues]);

  useEffect(() => {
    if (props.id > 0 && props.initialValues?.StatusDataUpdate?.length > 0) {
      setStatusArrayUpdate(props.initialValues.StatusDataUpdate);
    }
  }, [props.initialValues?.StatusDataUpdate?.length > 0]);

  const getBase64ById = (id: number): any => {
    const item = props.allImageOptions.find((item: any) => item.id === id);
    return item ? item.base64 : "";
  };

  const getIdByBase64 = (base64: string, folderId:number): any => {

    const item = props.allTenantImagesOptions.find(
      (item: any) => item.base64 === base64 && item.folder.FolderId === folderId 
    );
    return item ? item.id : -1;
  };

  const resetImageIds = (): any => {
    let obj = {
        CategoryId: categoryId,
        FullImage: -1,
        AvailImage: -1,
        CloseImage: -1,
        CrowdedImage: -1,
        FolderId: folderId,
        Height: height,
        Width: width,
      };
      props.onChangeInitialValues(obj, true);
  }

  /* This Method Adds the Status Image Row Data to the Add Array */
  const handleAdd = (data: any) => {
    const categoryExistsAdd = statusArrayAdd.some(
      (item) => item.CategoryId === data.CategoryId
    );
    const categoryExistsUpdate = statusArrayUpdate.some(
      (item) => item.CategoryId === data.CategoryId
    );
    if (
      data.CategoryId !== -1 &&
      data.AvailImageSource !== "" &&
      data.CloseImageSource !== "" &&
      data.CrowdedImageSource !== "" &&
      data.FullImageSource !== "" &&
      data.FolderId !== null &&
      data.Height !== null &&
      data.Width !== null
    ) {
      if (categoryExistsAdd || categoryExistsUpdate) {
        toast.error(formatMessage("category_already_exists"));
      } else {
        setStatusArrayAdd([...statusArrayAdd, { ...data }]);
      }
    }
  };

 /* This Method Edits the Status Image Row Data to the Edit Array */
  const handleEdit = (id: number) => {
    setCategoryId(id);
    setEditMode(true);
    const itemAdd = statusArrayAdd.find((item: any) => item.CategoryId === id);
    const itemUpdate = statusArrayUpdate.find(
      (item: any) => item.CategoryId === id
    );
    if (itemAdd) {
      let obj = {
        CategoryId: id,
        FullImage:  -1,
        AvailImage: -1,
        CloseImage: -1,
        CrowdedImage: -1,
        FolderId: itemAdd.FolderId,
        Height: itemAdd.Height,
        Width: itemAdd.Width,
      };
      props.onChangeInitialValues(obj,true);
    } else if (itemUpdate) {
      let obj = {
        CategoryId: id,
        FullImage:  -1,
        AvailImage: -1,
        CloseImage: -1,
        CrowdedImage: -1,
        FolderId: itemUpdate.FolderId,
        Height: itemUpdate.Height,
        Width: itemUpdate.Width,
      };
      props.onChangeInitialValues(obj,true);
    }
  };

  const handleUpdate = (updatedStatus: any) => {
    setStatusArrayAdd((prevStatusArray) =>
      prevStatusArray.map((item) =>
        item.CategoryId === updatedStatus.CategoryId ? updatedStatus : item
      )
    );
    setStatusArrayUpdate((prevStatusArray) =>
      prevStatusArray.map((item) => 
        item.CategoryId === updatedStatus.CategoryId
          ? {
            AvailImageId: item?.AvailImageId,
            AvailImageSource: updatedStatus.AvailImageSource,
            CategoryId: item?.CategoryId,
            CloseImageId: item?.CloseImageId,
            CloseImageSource: updatedStatus.CloseImageSource,
            CrowdedImageId: item?.CrowdedImageId,
            CrowdedImageSource: updatedStatus.CrowdedImageSource,
            FolderId: updatedStatus?.FolderId,
            FullImageId: item?.FullImageId,
            FullImageSource: updatedStatus?.FullImageSource,
            Height: updatedStatus?.Height,
            Width: updatedStatus?.Width
        }
          : item
      )
    );

    setEditMode(false);
    let obj = {
      CategoryId: -1,
      FullImage: -1,
      AvailImage: -1,
      CloseImage: -1,
      CrowdedImage: -1,
      FolderId: -1,
      Height: 500,
      Width: 500,
    };
    props.onChangeInitialValues(obj, false);
  };

  const getCategoryDescription = (catId: number):string => {
    if(props.allCategories?.length > 0){
      const category = props.allCategories?.find(cat => cat.Id === catId);
      return category ? category.Description : ""
    }
    else{
      return ""
    }
  }

  return (
    <React.Fragment>
      <Modal
        className="status-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="image_status" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          // validationSchema={AddParkingLotStatusImageSchema}
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            let tempValues = cloneDeep(values);
            props.submitHandler({
              ...tempValues,
              StatusDataAdd: statusArrayAdd,
              StatusDataUpdate: statusArrayUpdate,
            });
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body className="modal-status-from">
                  <div className="row">
                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) && (
                      <React.Fragment>
                        <div className="form-group mb-2 col-6">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="select_category"
                          />
                          <div className="">
                            <Field
                              data-testid="select_category"
                              className="form-control"
                              name="CategoryId"
                              placeholder="select_category"
                              disabled={isEditMode == true ? true : false}
                              options={props.allAvailableCategoryOptions}
                              setState={setCategoryId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-6">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="select_folder"
                          />
                          <div className="">
                            <Field
                              data-testid="select_folder"
                              className="form-control"
                              name="FolderId"
                              placeholder="select_folder"
                              options={props.allFolderOptions}
                              setState={setFolderId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-3">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="available_image"
                          />
                          <div className="">
                            <Field
                              data-testid="available_image"
                              className="form-control"
                              name="AvailImage"
                              options={allAvailableImages}
                              setState={setAvailId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-3">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="crowded_image"
                          />
                          <div className="">
                            <Field
                              data-testid="crowded_image"
                              className="form-control"
                              name="CrowdedImage"
                              type="number"
                              placeholder="crowded_image"
                              options={allAvailableImages}
                              setState={setCrowdedId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-3">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="full_image"
                          />
                          <div className="">
                            <Field
                              data-testid="full_image"
                              className="form-control"
                              name="FullImage"
                              options={allAvailableImages}
                              setState={setFullId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-3">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="close_image"
                          />
                          <div className="">
                            <Field
                              data-testid="close_image"
                              className="form-control"
                              name="ClosedImage"
                              type="number"
                              placeholder="close_image"
                              options={allAvailableImages}
                              setState={setClosedId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-6">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="media_height"
                          />
                          <div className="">
                            <Field
                              data-testid="media_height"
                              className="form-control"
                              name="Height"
                              placeholder="media_height"
                              type="number"
                              setState={setHeight}
                              as={FormikFormOnChangeInput}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 col-6">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="media_width"
                          />
                          <div className="">
                            <Field
                              data-testid="media_width"
                              className="form-control"
                              name="Width"
                              placeholder="media_width"
                              type="number"
                              setState={setWidth}
                              as={FormikFormOnChangeInput}
                            />
                          </div>
                        </div>

                        <div className="form-group mt-3 col-12 textAlign">
                          <Button
                            variant="light-primary"
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => {
                                if (height > 20000 || height < 0) {
                                  toast.error(formatMessage("height_max_min"));
                                } else if (width > 20000 || width < 0) {
                                  toast.error(formatMessage("width_max_min"));
                                } else {
                                  if(availableImageId == -1 || closedImageId == -1 || fullImageId == -1 || crowdedImageId == -1){
                                    toast.error(formatMessage('select_required'));
                                  }else{                                  
                                    handleAdd({
                                      CategoryId: categoryId,
                                      FolderId: folderId,
                                      AvailImageSource: getBase64ById(availableImageId),
                                      CloseImageSource: getBase64ById(closedImageId),
                                      FullImageSource: getBase64ById(fullImageId),
                                      CrowdedImageSource: getBase64ById(crowdedImageId),
                                      Height: height,
                                      Width: width,
                                    });
                                  }
                                }
                              }}
                            disabled={
                                isEditMode == true
                                  ? true
                                  : statusArrayAdd.length +
                                      statusArrayUpdate.length ==
                                    5
                                  ? true
                                  : false
                              }>
                            <FormattedMessage id="button_add" />
                          </Button>
                          <Button
                            variant="light-primary"
                            className="ms-2 btn btn-secondary"
                            type="button"
                            disabled={!isEditMode ? true : false}
                            onClick={() => {
                                if (height > 20000 || height < 0) {
                                  toast.error(formatMessage("height_max_min"));
                                } else if (width > 20000 || width < 0) {
                                  toast.error(formatMessage("width_max_min"));
                                } else {
                                    if(availableImageId == -1 || closedImageId == -1 || fullImageId == -1 || crowdedImageId == -1){
                                        toast.error(formatMessage('select_required'));
                                      }else{                                  
                                    handleUpdate({
                                      CategoryId: categoryId,
                                      FolderId: folderId,
                                      AvailImageSource: getBase64ById(availableImageId),
                                      CloseImageSource: getBase64ById(closedImageId),
                                      FullImageSource: getBase64ById(fullImageId),
                                      CrowdedImageSource: getBase64ById(crowdedImageId),
                                      AvailImageId: availableImageId,
                                      FullImageId: fullImageId,
                                      CloseImageId: closedImageId,
                                      CrowdedImageId: crowdedImageId,
                                      Height: height,
                                      Width: width,
                                    });
                                  }
                              }
                              }}
                          >
                            <FormattedMessage id="button_update" />
                          </Button>
                        </div>
                      </React.Fragment>
                    )}

                    {statusArrayAdd.length + statusArrayUpdate.length > 0 ? (
                      <React.Fragment>
                        <div className="tableDesign border modal-table-scroll">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="category_status_image" />
                                </th>
                                <th>
                                  <FormattedMessage id="available" />
                                </th>
                                <th>
                                  <FormattedMessage id="crowded" />
                                </th>
                                <th>
                                  <FormattedMessage id="full" />
                                </th>
                                <th>
                                  <FormattedMessage id="closed" />
                                </th>
                                <th>
                                  <FormattedMessage id="media_height" />
                                </th>
                                <th>
                                  <FormattedMessage id="media_width" />
                                </th>
                                {props.initialValues.IsUpdate &&
                                  isOperationPermittedParkingRoleBase(
                                    ModuleType.ParkingManagementButtons,
                                    "Edit"
                                  ) && (
                                    <th>
                                      <FormattedMessage id="action" />
                                    </th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {statusArrayAdd?.length > 0 &&
                                statusArrayAdd.map(
                                  (status: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        {status?.CategoryId !== 5
                                        ? 
                                        formatMessage(
                                          categoryEnum[status?.CategoryId]
                                        )
                                        + " ("+getCategoryDescription(status?.CategoryId)+")"
                                        

                                        :
                                        formatMessage(
                                          categoryEnum[status?.CategoryId]
                                        )
                                      }
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.AvailImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.CrowdedImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.FullImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.CloseImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Height}</div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Width}</div>
                                      </td>

                                      {props.initialValues.IsUpdate &&
                                        isOperationPermittedParkingRoleBase(
                                          ModuleType.ParkingManagementButtons,
                                          "Edit"
                                        ) && (
                                          <td>
                                            <button
                                              className={`btn btn-light-primary btn-sm`}
                                              type="button"
                                              disabled={
                                                isEditMode == true
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>{
                                                handleEdit(status.CategoryId);                                           
                                              }}
                                            >
                                              <svg
                                                id="_レイヤー_3"
                                                fill="#0091ff"
                                                width="21"
                                                height="21"
                                                data-name="レイヤー 3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                              >
                                                <path d="m26.08,1.63c1.26,0,2.28,1.02,2.28,2.28v22.16c0,1.26-1.03,2.28-2.28,2.28H3.92c-1.26,0-2.28-1.02-2.28-2.28V3.92c0-1.26,1.03-2.28,2.28-2.28h22.16m0-1.13H3.92C2.03.5.5,2.03.5,3.92v22.16c0,1.89,1.53,3.42,3.42,3.42h22.16c1.89,0,3.42-1.53,3.42-3.42V3.92c0-1.89-1.53-3.42-3.42-3.42h0Z" />
                                                <path d="m8.99,18.1l1.1,1.1,1.1,1.1-1.71.53-1.49.47.47-1.49.54-1.71m-.41-1.67l-.98,3.12-.98,3.12,3.12-.98,3.12-.98-2.14-2.14-2.14-2.14h0Z" />
                                                <polygon points="6.63 22.66 7.18 20.88 7.74 19.11 8.96 20.33 10.18 21.55 8.41 22.11 6.63 22.66" />
                                                <path d="m19.87,5.13l-10.82,10.82,4.29,4.29,10.82-10.82-4.29-4.29Zm-7.85,10.97l-.5-.5,7.86-7.86.5.5-7.86,7.86Z" />
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                    </tr>
                                  )
                                )}
                              {statusArrayUpdate?.length > 0 &&
                                statusArrayUpdate.map(
                                  (status: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        {status?.CategoryId !== 5
                                        ? 
                                        formatMessage(
                                          categoryEnum[status?.CategoryId]
                                        )
                                        + " ("+getCategoryDescription(status?.CategoryId)+")"
                                        

                                        :
                                        formatMessage(
                                          categoryEnum[status?.CategoryId]
                                        )
                                      }
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.AvailImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.CrowdedImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.FullImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.CloseImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Height}</div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Width}</div>
                                      </td>
                                      
                                      {props.initialValues.IsUpdate &&
                                        isOperationPermittedParkingRoleBase(
                                          ModuleType.ParkingManagementButtons,
                                          "Edit"
                                        ) && (
                                          <td>
                                            <button
                                              title={formatMessage("edit")}
                                              className={`btn btn-light-primary btn-sm`}
                                              type="button"
                                              disabled={
                                                isEditMode == true
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>{
                                                handleEdit(status.CategoryId);                                           
                                              }}
                                            >
                                              <svg
                                                id="_レイヤー_3"
                                                fill="#0091ff"
                                                width="21"
                                                height="21"
                                                data-name="レイヤー 3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                              >
                                                <path d="m26.08,1.63c1.26,0,2.28,1.02,2.28,2.28v22.16c0,1.26-1.03,2.28-2.28,2.28H3.92c-1.26,0-2.28-1.02-2.28-2.28V3.92c0-1.26,1.03-2.28,2.28-2.28h22.16m0-1.13H3.92C2.03.5.5,2.03.5,3.92v22.16c0,1.89,1.53,3.42,3.42,3.42h22.16c1.89,0,3.42-1.53,3.42-3.42V3.92c0-1.89-1.53-3.42-3.42-3.42h0Z" />
                                                <path d="m8.99,18.1l1.1,1.1,1.1,1.1-1.71.53-1.49.47.47-1.49.54-1.71m-.41-1.67l-.98,3.12-.98,3.12,3.12-.98,3.12-.98-2.14-2.14-2.14-2.14h0Z" />
                                                <polygon points="6.63 22.66 7.18 20.88 7.74 19.11 8.96 20.33 10.18 21.55 8.41 22.11 6.63 22.66" />
                                                <path d="m19.87,5.13l-10.82,10.82,4.29,4.29,10.82-10.82-4.29-4.29Zm-7.85,10.97l-.5-.5,7.86-7.86.5.5-7.86,7.86Z" />
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="message text-center modal-message">
                          <img
                            src={noMessageIcon}
                            alt=""
                            className="no_message"
                          />
                          <p className="noData text-muted text-center h4 mb-0">
                            <FormattedMessage id="no_data" />
                          </p>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>

                      {isOperationPermittedParkingRoleBase(
                        ModuleType.ParkingManagementButtons,
                        "Add"
                      ) && (
                        <LoaderButton
                          type="submit"
                          isLoading={props.isLoading}
                          disabled={(statusArrayAdd?.length > 0 || statusArrayUpdate?.length > 0) ? false : true}
                          text="button_save"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddEditParkingLotStatusImageForm);
