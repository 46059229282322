/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-camera.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { permissionTypeOptions } from "../../../constants/options.constant";
import {
  IAddHucksterUser,
  IUpdateHucksterUser,
} from "../../../models/forms/IAddUser";
import { formatMessage } from "../../../translations/format-message";
import IAddBranch, {
  IUpdateBranch,
} from "../../../models/forms/IAddUpdateBranch";
import AddUpdateCameraForm from "../forms/add-edit-camera-form";
import IAddCamera, { IUpdateCamera } from "../../../models/forms/IAddUpdateCamera";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import _ from "lodash";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddCamera = (props: IProps) => {
  const { parkingLotStore, mediaStore, preferencesStore, cameraStore } =
    useStore();
  const { getTenantAccess, getParkingLotCameraAccess } = preferencesStore;
  const {
    AddCameraService,
    UpdateCameraService,
    GetCameraService,
    reset,
    resetGetCameraDetail,
    addUpdateCameraState,
    cameraDetails,
    cameraState,
    cameraSlot,
    getCameraSlotById,
    GetCameraSlotByIdService
  } = cameraStore;
  const { GetAllFolders, getAllFolderState , allAvailableImages, getAllImagesState ,allCarSlotImageFolders , resetGetAllImagesState } = mediaStore;
  const { GetAllParkingLotCategoryService, allAvailableParkingLotCategorySlotType } = parkingLotStore;
  const initialValues: any = cameraDetails; 

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllFolders(getTenantAccessFromLocalStorage());
    GetAllParkingLotCategoryService(getTenantAccessFromLocalStorage(), getParkingLotCameraAccess.plId);
    if (props.id > 0) {
      GetCameraSlotByIdService(props.id, getTenantAccessFromLocalStorage());
      GetCameraService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateCameraState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      reset();
      onModalClosed();
    }
  }, [addUpdateCameraState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (cameraState.error) {
      toast.error(formatMessage(cameraState.error));
    }
  }, [cameraState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {

    if (addUpdateCameraState.error) {
      toast.error(formatMessage(addUpdateCameraState.error));
      reset();
    }
  }, [addUpdateCameraState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let slots = [];
    let newSlots = [];
    let oldSlots = [];
    let isSlotsChanged: boolean = false;
    for(var i=1; i<=6; i++){
      if(props.id < 0){
        let slotObj = {
          Category: Number(values[`isSlotEnable${i}`]) == 1 ? 0 : Number(values[`slotType_${i}`]),
          ImageStyle: values.ImageStyle,
          AvailImage: values.AvailableImageBase64,
          FullImage: values.FullImageBase64,
          ClosedImage: values.CloseImageBase64,
          Status: Number(values[`isSlotEnable${i}`]),
          SlotAbsoluteNumber: i
        }
        slots.push(slotObj);
      }
      else{
        let slotObj = {
          SlotId: values[`slotId_${i}`],
          Category: Number(values[`isSlotEnable${i}`]) == 1 ? 0 : Number(values[`slotType_${i}`]),
          ImageStyle: values.ImageStyle,
          AvailImage: values.AvailableImageBase64,
          FullImage: values.FullImageBase64,
          ClosedImage: values.CloseImageBase64,
          Status: Number(values[`isSlotEnable${i}`]),
          SlotAbsoluteNumber: i
        }

        let newSlot = {
          SlotId: values[`slotId_${i}`],
          Category: Number(values[`isSlotEnable${i}`]) == 1 ? 0 : Number(values[`slotType_${i}`]),
          Status: Number(values[`isSlotEnable${i}`]),
        }

        let oldSlot = {
          SlotId: values.oldData[`slotId_${i}`],
          Category: Number(values?.oldData[`isSlotEnable${i}`]) == 1 ? 0 : Number(values.oldData[`slotType_${i}`]),
          Status: Number(values?.oldData[`isSlotEnable${i}`]),
        }
        newSlots.push(newSlot);
        oldSlots.push(oldSlot);
        slots.push(slotObj);
      }
    }
    if(!_.isEqual(newSlots, oldSlots)){
      isSlotsChanged = true;
    }
    if (props.id < 0) {
      var addObj: IAddCamera = {
        TenantId: getTenantAccessFromLocalStorage(),
        CameraName: values.CameraName,
        PlId: getParkingLotCameraAccess.plId,
        MacAddress: values.MacAddress,
        IpAddress: values.IpAddress,
        Comment: values.Comment,
        Height: Number(values.Height),
        Width: Number(values.Width),
        CameraSlotDetails: slots

      };
      AddCameraService(addObj);
    } else {
      var updateObj: IUpdateCamera = {
        TenantId: getTenantAccessFromLocalStorage(),
        CameraId: Number(values.CameraId),
        CameraName: values.CameraName,
        PlId: getParkingLotCameraAccess.plId,
        MacAddress: values.MacAddress,
        IpAddress: values.IpAddress,
        Comment: values.Comment,
        Height: Number(values.Height),
        Width: Number(values.Width),
        IsSlotChanged: isSlotsChanged,
        UpdateSlot: slots
      };
      UpdateCameraService(updateObj);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetGetCameraDetail();
    resetGetAllImagesState();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateCameraForm
        initialValues={initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        allSlotTypeOptions = {allAvailableParkingLotCategorySlotType}
        allImageOptions={allAvailableImages}
        allFolderOptions={allCarSlotImageFolders}
        isLoading={cameraState.inProgress || addUpdateCameraState.inProgress || getAllFolderState.inProgress || getCameraSlotById.inProgress || getAllImagesState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddCamera);
