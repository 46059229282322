/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import { ITenantState } from "../../models/state/ITenantState";
import {
  ITenantList,
  ITenantListVM,
  ITenantVM,
} from "../../models/response/ITenantResponse";
import * as baseService from '../service/base-service';
import { initialState as allTenantInitialState } from "../initialState/get-all-tenant-state";
import { initialState as addTenantInitialState } from "../initialState/add-tenant-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import IAddTenant, { IUpdateTenant } from "../../models/forms/IAddUpdateTenant";

export class TenantStore implements ITenantState, ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  tenantDataList: ITenantListVM = allTenantInitialState;
  allTenants: ITenantVM[] = [];

  tenant: any = undefined;
  addUpdateTenantState = { ...this.initialStateValue };
  deleteTenantState = { ...this.initialStateValue };
  tenantState = { ...this.initialStateValue };
  changePasswordState = { ...this.initialStateValue };
  getAllTenantState = {...this.initialStateValue};

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      tenant: observable,
      tenantDataList: observable,
      addUpdateTenantState: observable,
      deleteTenantState: observable,
      tenantState: observable,
      allTenants: observable,
      getAllTenantState: observable,
      AddTenantService: action,
      UpdateTenantService: action,
      GetTenantListService: action,
      GetTenantService: action,
      GetAllTenants:action,
      DeleteTenantService: action,
      resetGetTenantDetail:action,
      resetStore:action,
      reset: action,
      resetAddUpdateTenantState: action,
      allTenantlist: computed,
      tenantDetails: computed,
      allAvailableTenants: computed
    });
  }

  /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetTenantListService = (pageNumber: number, pageSize: number) => {
    this.inProgress = true;
    const url =
      URLConstants.TenantList +
      "/" +
      "List/" +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ITenantListVM>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
        }
        this.tenantDataList = response.data.Data;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  GetAllTenants = () => {
    this.getAllTenantState.inProgress = true;
    const url =
      URLConstants.AllTenants + '/All';
      return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getAllTenantState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allTenants = response.data.Data;
          this.getAllTenantState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllTenantState.inProgress = false;
        })
      );
  };

      /**
    * This function is used to map available group List for Add Device Component with AwsIoTCore Configuration.
    * @returns Initial Group Details
    */
      get allAvailableTenants(): IOption[] {
        const tenantOptions: IOption[] = [{
            id: -1,
            value: "please_select",
            disabled: true
        }];
        if (this.allTenants && this.allTenants?.length > 0)
            this.allTenants.map((tenant) => {
                tenantOptions.push({
                    id: tenant.TenantId,
                    value: tenant.OrganizationName,
                    disabled: false,
                })
            })
        return tenantOptions;
    }

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allTenantlist(): ITenantList[] {
    if (this.tenantDataList && this.tenantDataList?.Tenants.length > 0) {
      return this.tenantDataList?.Tenants.map((tenant) => {
        let isDelete = true;
        let isEdit = true;
        let isChangePassword = false;
        let accessTenant = true;
        return {
          Id: tenant.TenantId,
          OrganizationId: tenant.OrganizationId,
          OrganizationName: tenant.OrganizationName,
          Address: tenant.Address,
          Comment: tenant.Comment,
          User: tenant.user,
          CreatedBy: tenant.CreatedBy,
          UpdatedBy: tenant.UpdatedBy,
          isEdit: isEdit,
          accessTenant: accessTenant,
          isDelete: isDelete,
          isChangePassword: isChangePassword,
        };
      });
    }
    return [];
  }

  /**
   * This function is used to Get Tenant Details by calling an API.
   * @param tenantId : Tenant Identifier
   * @param userId : User Identifier
   * @returns
   */
  GetTenantService = (tenantId: number) => {
    this.tenantState.inProgress = true;
    const url = URLConstants.GetTenantById + "?TenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ITenantVM>>) => {
        if (response.data.Error) {
          this.tenantState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.tenant = response.data.Data;
          this.tenantState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.tenantState.inProgress = false;
        })
      );
  };

  /**
   * This function provides initail values to the Add Update Device Form.
   * @returns Initial Device Details
   */
  get tenantDetails(): any {
    if (this.tenant)
      return {
        Id: this.tenant.tenantId,
        // Email: this.tenant.Email,
        // UserName: this.tenant.UserName,
        // UserType: this.tenant.UserType,
        // PermissionType: this.tenant.PermissionType,
        OrganizationName: this.tenant.OrganizationName,
        OrganizationId: this.tenant.OrganizationId,
        Address: this.tenant.Address,
        Comment: this.tenant.Comment,
        IsAddUser: this.tenant.IsAddUser ? this.tenant.IsAddUser : false,
      };
    return addTenantInitialState;
  }

  /**
   * This function is used to reset getDeviceDetail observables to their initial values.
   * @returns
   */
  resetGetTenantDetail = () => {
    this.tenant = undefined;
    this.tenantState = { ...this.initialStateValue };
  };

  /**
   * This function is used to Add New Tenant by calling an API & sending the required tenant details.
   * @param tenant : Tenant Details
   * @returns
   */
  AddTenantService = (tenant: IAddTenant) => {
    this.addUpdateTenantState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddTenant, tenant)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateTenantState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateTenantState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateTenantState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing tenant by calling an API & sending updated tenant details.
   * @param id : Tenant identifier
   * @param tenant : Tenant Details
   * @returns
   */
  UpdateTenantService = (id: number, tenant: any) => {
    this.addUpdateTenantState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateTenant+"?Id="+id, tenant)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateTenantState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateTenantState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdateTenantState.inProgress = false;
        })
      );
  };

  resetAddUpdateTenantState = () =>{
    this.addUpdateTenantState = {...this.initialStateValue};
  }

  /**
   * This function is used to delete existing tenant by calling an API.
   * @param id : Tenant identifier
   * @returns
   */
  DeleteTenantService = (id: number) => {
    this.deleteTenantState.inProgress = true;
    const url = URLConstants.DeleteTenant + "/" + id;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteTenantState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteTenantState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteTenantState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.deleteTenantState = { ...this.initialStateValue };
    this.addUpdateTenantState = { ...this.initialStateValue };
    this.getAllTenantState = {...this.initialStateValue};
    this.changePasswordState = { ...this.initialStateValue };
  };

      /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
      resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.tenantDataList = allTenantInitialState;
        this.tenant = undefined;
        this.addUpdateTenantState = { ...this.initialStateValue };
        this.deleteTenantState = { ...this.initialStateValue };
        this.getAllTenantState = {...this.initialStateValue};
        this.tenantState = { ...this.initialStateValue };
        this.changePasswordState ={...this.initialStateValue };
    }
}

export default new TenantStore();
