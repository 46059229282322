import React, { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import "./add-edit-integrator-threshold-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import LoaderButton from "../../../shared-components/button/loader-button";
import { IOption } from "../../../models/ICommon";
import { ICameraSlotCountVM } from "../../../models/response/ICameraResponse";
import categoryEnum from "../../../constants/enums/category-enum";
import { formatMessage } from "../../../translations/format-message";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import SliderComponent from "../../../shared-components/slider/slider";

interface IProps {
  id: number;
  initialValues: any;
  integratorID: string;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
}

const AddUpdateIntegratorThresholdForm = (props: IProps) => {
  const [thresholdData, setThresholdData] = useState<any>();

  useEffect(() => {
    const transformedData = {
      ThresholdId: props.initialValues?.ThresholdId,
      TotalParkingCount: props.initialValues?.TotalParkingCount,
      AvailUpto: props.initialValues?.AvailUpto,
      FullAfter: props.initialValues?.FullAfter,
    };
    setThresholdData(transformedData);
  }, [props.initialValues]);

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (Array.isArray(value)) {
      setThresholdData((prevObject: any) => ({
        ...prevObject,
        AvailUpto: value[0],
        FullAfter: value[1],
      }));
    }
  };

  return (
    <>
      <Modal
        className="threshold-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={props.onModalClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="threshold_management" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          // validationSchema={setThresholdValidationSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler({
              ...values,
              ThresholdSliderData: thresholdData,
            });
          }}
        >
          {({ dirty, setFieldValue }) => (
            <Form className="user-form">
              {props.isLoading && <FormLoader loading={props.isLoading} />}
              <Modal.Body>
                <div className="tableDesign border">
                  <table className="table table-bordered border camera-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="integrator_id" />
                        </th>
                        <th>
                          <FormattedMessage id="count_limit" />
                        </th>
                        <th>
                          <div className="d-flex mb-2">
                            <div className="circleGreen me-2"></div>
                            <FormattedMessage id="available_threshold" />
                          </div>
                          <div className="d-flex mb-2">
                            <div className="circleYellow me-2"></div>
                            <FormattedMessage id="crowded_threshold" />
                          </div>
                          <div className="d-flex mt-1">
                            <div className="circleRed me-2"></div>
                            <FormattedMessage id="full_threshold" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{[props.integratorID]}</td>
                        <td>{thresholdData?.TotalParkingCount}</td>
                        <td>
                          <SliderComponent
                            value={[
                              thresholdData?.AvailUpto,
                              thresholdData?.FullAfter,
                            ]}
                            min={0}
                            max={thresholdData?.TotalParkingCount}
                            categoryType={thresholdData?.ThresholdId}
                            submitHandler={(
                              event: Event,
                              value: number | number[]
                            ) => {
                              handleSliderChange(event, value);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>

                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) && (
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateIntegratorThresholdForm;
