/*    
<summary>
   This component is Managing Branch Management & performing CRUD operations on the Branches.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:04-April-2024, Last Updated By: Mohammad Saquib Khan, Updated Date: 05-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./weekday-scheduler.css";
import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { useStore } from "../../contexts/store-provider";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import { formatMessage } from "../../translations/format-message";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import Pagination from "../../shared-components/Grid/Pagination";
import LoaderButton from "../../shared-components/button/loader-button";
import AddWeekdaySchedulerPopupHOC from "./hoc/AddWeekdaySchedulerPopupHOC";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import AddSchedulerDigitMappingPopupHOC from "./hoc/AddSchedulerDigitMappingPopupHOC";
import AddEditWeekdayScheduler from "./components/add-edit-weekday-scheduler/add-edit-weekday-scheduler";
import AddEditWeekdayDigitMapping from "./components/add-edit-weekday-digit-mapping/add-edit-weekday-digit-mapping";
import AddEditSpecialScheduler from "./components/add-edit-special-scheduler/add-edit-special-scheduler";
import AddSpecialSchedulerPopupHOC from "./hoc/AddSpecialSchedulerPopupHOC";
import scheduleTypeEnum from "../../constants/enums/schedule-type-enum";
import { useHistory, useLocation } from "react-router-dom";
import RoutesConstants from "../../constants/routes.constant";
import {
  getIsTenantAccessFromLocalStorage,
  getTenantAccessFromLocalStorage,
} from "../../helpers/localstorage-helper";
import SelectGroupsPopup from "./choose-csv-popup";
import ChooseGroupPopupHOC from "./hoc/ChooseGroupPopupHOC";
import {
  isOperationPermittedParkingRoleBase,
  isOperationPermittedRoleBase,
  showIconsRoleBase,
} from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import SignBoardPopupHOC from "./hoc/SignBoardPopupHOC";
import UploadSignboard from "./components/signboard/upload-signboard";

const WeekdayScheduler = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const parkingLotName: any = location.state;
  const { preferencesStore, authStore, priceSchedulerStore } = useStore();
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    getPriceSchedulerAccess,
    setIsSpecialSchedulerActive,
    getIsSpecialScheduleActive,
  } = preferencesStore;
  const {
    GetPriceSchedulerListService,
    addUpdatePriceSchedulerState,
    schedulerList,
    resetAddUpdatePriceScheduler,
    allPriceSchedulerList,
    resetGetPriceSchedulerDetail,
    inProgress,
    deletePriceSchedulerState,
    DeletePriceSchedulerService,
    DeleteSpecialSchedulerService,
    GetSpecialSchedulerListService,
    resetGetSpecialSchedulerDetail,
    resetAddUpdateSpecialScheduler,
    addUpdateSpecialSchedulerState,
    deleteSpecialSchedulerState,
    allSpecialSchedulerList,
    resetAddScheduleCSVDetail,
    addSchedulerCSVState,
    resetDeleteSpecialScheduler,
    resetDeletePriceScheduler,
    GetSignBoardService,
    getSignBoardState,
    getSignBoardImage,
    addSignBoardState,
  } = priceSchedulerStore;

  let lang: string;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const allWeekdayScheduler: Array<any> = allPriceSchedulerList;
  // const allSpecialScheduler: Array<any> = specialSchedulerDemoData;
  const allSpecialScheduler: Array<any> = allSpecialSchedulerList;

  const [isSpecialScheduleActive, setSpecialScheduleActive] = useState<boolean>(
    getIsSpecialScheduleActive
  );

  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  // useEffect(() => {
  //   var data = getIsSpecialScheduleActive;
  //   if(data){
  //     setIsSpecialSchedulerActive(data);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  /**
   * This function calls the "getAllBranch" store funtion that get branch list from API
   */
  const callGetPriceSchedulerListService = () => {
    GetPriceSchedulerListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      getTenantAccessFromLocalStorage(),
      getPriceSchedulerAccess.plId
    );

    GetSpecialSchedulerListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      getTenantAccessFromLocalStorage(),
      getPriceSchedulerAccess.plId
    );

    GetSignBoardService(
      getPriceSchedulerAccess.plId,
      getTenantAccessFromLocalStorage()
    );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetPriceSchedulerListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "schedulerList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: schedulerList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulerList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetPriceSchedulerListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * This function shows the add weekday scheduler pop up from where we can add new weekday scheduler and update existing weekday scheduler. we use id to identify
   * that we are adding or updating weekday scheduler.
   * "Id == -1" : we are adding new weekday scheduler
   * "Id != -1" : means we are updating existing weekday scheduler
   */
  const openAddWeekdaySchedulerHandler = (
    row: { PsId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    resetGetPriceSchedulerDetail();
    setSelectedId(row.PsId);
    props.addWeekdaySchedulerToggleHandler();
  };

  const openAddSpecialSchedulerHandler = (
    row: { SsId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    // resetGetPriceSchedulerDetail();
    setSelectedId(row.SsId);
    props.addSpecialSchedulerToggleHandler();
  };

  const openDigitMappingHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    resetGetPriceSchedulerDetail();
    props.addSchedulerDigitMappingToggleHandler();
  };

  const uploadSignBoardHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event?.stopPropagation();
    // resetGetPriceSchedulerDetail();
    props.signBoardPopupToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetPriceSchedulerListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the Price Scheduler by providing Id to the Api
   */
  const deletePriceSchedulerHandler = () => {
    DeletePriceSchedulerService(selectedId, getTenantAccessFromLocalStorage());
  };

  const deleteSpecialSchedulerHandler = () => {
    DeleteSpecialSchedulerService(
      selectedId,
      getTenantAccessFromLocalStorage()
    );
  };

  /**
   * This function shows the delete branch confirmation popup component and sets the seleted branch Id
   */
  const deletePriceSchedulerClickHandler = (
    row: { PsId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.PsId);
    event.stopPropagation();
    resetGetPriceSchedulerDetail();
    props.deletePopupToggleHandler();
  };

  /**
   * This function shows the delete branch confirmation popup component and sets the seleted branch Id
   */
  const deleteSpecialSchedulerClickHandler = (
    row: { SsId: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.SsId);
    event.stopPropagation();
    resetGetSpecialSchedulerDetail();
    props.deletePopupToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdatePriceSchedulerState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdatePriceSchedulerState.success) {
      callGetPriceSchedulerListService();
      resetAddUpdatePriceScheduler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdatePriceSchedulerState.success]);

  useEffect(() => {
    if (addSchedulerCSVState.success) {
      callGetPriceSchedulerListService();
      resetAddScheduleCSVDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSchedulerCSVState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdatePriceSchedulerState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateSpecialSchedulerState.success) {
      callGetPriceSchedulerListService();
      resetAddUpdateSpecialScheduler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateSpecialSchedulerState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deletePriceSchedulerState" of the useEffect changed.
   */
  useEffect(() => {
    if (deletePriceSchedulerState.success) {
      toast.success(formatMessage("deleted_success"));
      props.deletePopupToggleHandler();
      resetDeletePriceScheduler();
      callGetPriceSchedulerListService();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePriceSchedulerState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deletePriceSchedulerState" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteSpecialSchedulerState.success) {
      toast.success(formatMessage("deleted_success"));
      props.deletePopupToggleHandler();
      resetDeleteSpecialScheduler();
      callGetPriceSchedulerListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSpecialSchedulerState.success]);

  useEffect(() => {
    if (addSignBoardState.success) {
      callGetPriceSchedulerListService();
    }
  }, [addSignBoardState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetPriceSchedulerListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetPriceSchedulerListService();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.PsId && row.PsId === selectedRow.PsId) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const switchView = (event: any) => {
    if (event.target.defaultValue == "Weekday") {
      // setIsSpecialSchedulerActive(false);
      setSpecialScheduleActive(false);
    } else {
      // setIsSpecialSchedulerActive(true);
      setSpecialScheduleActive(true);
    }
  };

  const addCSVData = () => {
    // closePanelHandler();
    props.chooseGroupToggleHandler();
  };

  const weekdayActionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isEdit",
        handler: openAddWeekdaySchedulerHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "edit",
        varient: "primary",
        action: "edit",
      },
      {
        dataField: "isDelete",
        handler: deletePriceSchedulerClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        varient: "danger",
        action: "delete",
      },
    ],
    ModuleType.PriceSchedular
  );

  /**
   * The column constant defines the column description for the branch grid (table)
   */
  const priceScheduleColumns: any = [
    {
      dataField: "PsId",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "Weekday",
      text: formatMessage("day"),
      // Uncomment and use this formatter if needed
      // formatter: (cell: any, row: any) => {
      //   return <span>{weekdayEnum[row?.Weekday]}</span>;
      // },
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      formatter: (cell: any, row: any) => {
        return cell == "" ? "" : <FormattedMessage id={cell} />;
      },
      attrs: (cell: string, row: any) => {
        return { rowspan: row.WeekdayTotalCount || 1 }; // Ensure rowspan is at least 1
      },
      classes: "rowspan-col",
    },
    {
      dataField: "ScheduleCount",
      text: formatMessage("schedule_count"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "StartTime",
      text: formatMessage("start_time"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data1",
      text: formatMessage("data_1"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data2",
      text: formatMessage("data_2"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data3",
      text: formatMessage("data_3"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data4",
      text: formatMessage("data_4"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data5",
      text: formatMessage("data_5"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data6",
      text: formatMessage("data_6"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(weekdayActionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: weekdayActionIcons?.length > 0 ? false : true,
    },
  ];

  const specialActionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isEdit",
        handler: openAddSpecialSchedulerHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "edit",
        varient: "primary",
        action: "edit",
      },
      {
        dataField: "isDelete",
        handler: deleteSpecialSchedulerClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        varient: "danger",
        action: "delete",
      },
    ],
    ModuleType.PriceSchedular
  );

  const specialScheduleColumns: any = [
    {
      dataField: "SsId",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "ScheduleType",
      text: formatMessage("schedule_type_thead"),
      formatter: (cell: any, row: any) => {
        return (
          <span>
            <FormattedMessage id={scheduleTypeEnum[cell]} />
          </span>
        );
      },
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "StartDate",
      text: formatMessage("start_date"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "StartTime",
      text: formatMessage("start_time"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "EndDate",
      text: formatMessage("end_date"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "EndTime",
      text: formatMessage("end_time"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data1",
      text: formatMessage("data_1"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data2",
      text: formatMessage("data_2"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data3",
      text: formatMessage("data_3"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data4",
      text: formatMessage("data_4"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data5",
      text: formatMessage("data_5"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Data6",
      text: formatMessage("data_6"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(specialActionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: specialActionIcons?.length > 0 ? false : true,
    },
  ];

  return (
    <React.Fragment>
      {inProgress && (
        <FormLoader
          loading={
            inProgress ||
            addUpdatePriceSchedulerState.inProgress ||
            deletePriceSchedulerState.inProgress ||
            getSignBoardState.inProgress
          }
        />
      )}

      {props.showAddSpecialSchedulerPopup && (
        <AddEditSpecialScheduler
          id={selectedId}
          modalClosed={props.addSpecialSchedulerToggleHandler}
        />
      )}

      {props.showAddWeekdaySchedulerPopup && (
        <AddEditWeekdayScheduler
          id={selectedId}
          modalClosed={props.addWeekdaySchedulerToggleHandler}
        />
      )}

      {props.showAddSchedulerDigitMappingPopup && (
        <AddEditWeekdayDigitMapping
          id={selectedId}
          modalClosed={props.addSchedulerDigitMappingToggleHandler}
        />
      )}

      {props.showSignBoardPopupHOC && (
        <UploadSignboard modalClosed={props.signBoardPopupToggleHandler} />
      )}

      {props.showDeletePopup && (
        <DeletePopup
          title={
            isSpecialScheduleActive
              ? "delete_special_scheduler"
              : "delete_weekday_scheduler"
          }
          modalSubmit={
            isSpecialScheduleActive
              ? deleteSpecialSchedulerHandler
              : deletePriceSchedulerHandler
          }
          modalClosed={props.deletePopupToggleHandler}
          message={
            isSpecialScheduleActive
              ? "message_delete_special_scheduler_confirm"
              : "message_delete_weekday_scheduler_confirm"
          }
          isLoading={
            isSpecialScheduleActive
              ? deleteSpecialSchedulerState.inProgress
              : deletePriceSchedulerState.inProgress
          }
        />
      )}

      {props.showChooseGroupPopup && (
        <SelectGroupsPopup
          // allDevices={allDeviceslist}
          modalClosed={props.chooseGroupToggleHandler}
        />
      )}

      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-4 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="PriceScheduler" />
              </h1>
              <ol className="breadcrumb">
                <li title={formatMessage("back")} className="back-icon pe-1">
                  <a
                    onClick={() =>
                      history.push(RoutesConstants.ParkingLotManagement)
                    }
                  >
                    <svg
                      version="1.1"
                      height="14"
                      width="14"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 447.243 447.243"
                    >
                      <g>
                        <g>
                          <path
                            d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
                      c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
                      l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
                      c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
                      l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
                      C449.654,211.494,437.806,195.059,420.361,192.229z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                <li className="breadcrumb-item">{parkingLotName}</li>
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="PriceScheduler" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-md-8 col-12">
            <ul className="add-list">
              <li>
                <div className="switches-container">
                  <input
                    type="radio"
                    id="switchWeekday"
                    name="switchPlan"
                    defaultChecked={
                      isSpecialScheduleActive == false ? true : false
                    }
                    onClick={(e: any) => {
                      switchView(e);
                    }}
                    value="Weekday"
                  />
                  <input
                    type="radio"
                    id="switchSpecial"
                    name="switchPlan"
                    onClick={(e: any) => {
                      switchView(e);
                    }}
                    defaultChecked={
                      isSpecialScheduleActive == true ? true : false
                    }
                    value="Special"
                  />
                  <br />
                  <label htmlFor="switchWeekday">
                    <FormattedMessage id="weekday" />
                  </label>
                  <label htmlFor="switchSpecial">
                    <FormattedMessage id="special" />
                  </label>
                  <div className="switch-wrapper">
                    <div className="toggleswitch">
                      <div>
                        <FormattedMessage id="weekday_switch" />
                      </div>
                      <div>
                        <FormattedMessage id="special" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <React.Fragment>
                {(isOperationPermittedParkingRoleBase(
                  ModuleType.PriceSchedularDigitMappingButton,
                  "Add"
                ) ||
                  isOperationPermittedParkingRoleBase(
                    ModuleType.PriceSchedularDigitMappingButton,
                    "View"
                  )) && (
                  <li title={formatMessage("digit_mapping")} className="ms-1">
                    <a
                      className="btn btn-secondary"
                      data-toggle="modal"
                      onClick={(event: any) => openDigitMappingHandler(event)}
                      data-testid="modalbtn"
                      data-target="#addUser"
                    >
                      <FormattedMessage id="digit_mapping" />
                    </a>
                  </li>
                )}

                {isSpecialScheduleActive == false
                  ? isOperationPermittedRoleBase(
                      ModuleType.PriceSchedular,
                      "Add"
                    ) && (
                      <React.Fragment>
                        <li
                          title={formatMessage("add_weekday_scheduler")}
                          className="ms-1"
                        >
                          <a
                            className="btn btn-secondary"
                            data-toggle="modal"
                            onClick={(event: any) =>
                              openAddWeekdaySchedulerHandler(
                                { PsId: -1 },
                                event
                              )
                            }
                            data-testid="modalbtn"
                            data-target="#addUser"
                          >
                            <svg
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus m-0"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            <FormattedMessage id="add_weekday_scheduler" />
                          </a>
                        </li>
                      </React.Fragment>
                    )
                  : isOperationPermittedRoleBase(
                      ModuleType.PriceSchedular,
                      "Add"
                    ) && (
                      <>
                        <li
                          title={formatMessage("add_special_scheduler")}
                          className="ms-1"
                        >
                          <a
                            className="btn btn-secondary"
                            data-toggle="modal"
                            onClick={(event: any) =>
                              openAddSpecialSchedulerHandler(
                                { SsId: -1 },
                                event
                              )
                            }
                            data-testid="modalbtn"
                            data-target="#addUser"
                          >
                            <svg
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus m-0"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            <FormattedMessage id="add_special_scheduler" />
                          </a>
                        </li>
                        <li title={formatMessage("add_csv_popup")}>
                          <a
                            className="btn btn-light"
                            onClick={() => {
                              addCSVData();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              fill="#0091ff"
                              className="bi bi-filetype-csv"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"
                              />
                            </svg>
                          </a>
                        </li>
                      </>
                    )}
              </React.Fragment>
              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
              <li
                title={
                  getSignBoardImage?.IsSignBoardAvailable == false
                    ? formatMessage("add_signboard")
                    : formatMessage("update_signboard")
                }
              >
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={(e: any) => uploadSignBoardHandler(e)}
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    height={20}
                    width={20}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                  >
                    <g>
                      <g>
                        <path
                          d="M461.913,0H50.087C22.469,0,0,22.469,0,50.087v300.522c0,27.618,22.469,50.087,50.087,50.087h139.13v77.913h-55.652
                      c-9.22,0-16.696,7.475-16.696,16.696S124.345,512,133.565,512c8.616,0,232.701,0,244.87,0c9.22,0,16.696-7.475,16.696-16.696
                      s-7.475-16.696-16.696-16.696h-55.652v-77.913h139.13c27.618,0,50.087-22.469,50.087-50.087V50.087
                      C512,22.469,489.531,0,461.913,0z M289.391,478.609h-66.783v-77.913h66.783V478.609z M478.609,350.609
                      c0,9.206-7.49,16.696-16.696,16.696c-5.109,0-399.895,0-411.826,0c-9.206,0-16.696-7.49-16.696-16.696V50.087
                      c0-9.206,7.49-16.696,16.696-16.696h411.826c9.206,0,16.696,7.49,16.696,16.696V350.609z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M428.522,66.783H83.478c-9.22,0-16.696,7.475-16.696,16.696v233.739c0,9.22,7.475,16.696,16.696,16.696h345.043
                      c9.22,0,16.696-7.475,16.696-16.696V83.478C445.217,74.258,437.742,66.783,428.522,66.783z M411.826,300.522H100.174V100.174
                      h311.652V300.522z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M301.197,171.847l-33.388-33.389c-6.605-6.604-17.209-6.407-23.617,0l-33.388,33.389c-6.52,6.519-6.52,17.091,0,23.611
                      c6.518,6.519,17.09,6.519,23.609-0.001l4.891-4.891v59.868c0,9.22,7.475,16.696,16.696,16.696c9.22,0,16.696-7.475,16.696-16.696
                      v-59.867l4.891,4.891c6.52,6.52,17.091,6.52,23.611,0C307.718,188.939,307.718,178.367,301.197,171.847z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* {isOperationPermittedParkingRoleBase(
        ModuleType.PriceSchedularDigitMappingButton,
        "Add"
      )?.toString()}
      {isOperationPermittedParkingRoleBase(
        ModuleType.PriceSchedularDigitMappingButton,
        "View"
      )?.toString()} */}
      {isSpecialScheduleActive == false ? (
        allWeekdayScheduler.length > 0 ? (
          <div>
            <div className="card">
              <div className="tableDesign row-eq-height tableDevices ">
                <div className="table-responsive" data-testid="table">
                  <Grid
                    data={allWeekdayScheduler}
                    columns={priceScheduleColumns}
                    keyField="PsId"
                    loading={false}
                  />
                </div>
              </div>
            </div>
            {allWeekdayScheduler.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <img src={noMessageIcon} alt="" className="no_message" />
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_weekday_scheduler" />
            </p>
            <p className="mb-3">
              {formatMessage("no_weekday_scheduler_message")}
            </p>
            {isOperationPermittedRoleBase(ModuleType.PriceSchedular, "Add") && (
              <LoaderButton
                text="add_weekday_scheduler"
                onClick={(event: any) =>
                  openAddWeekdaySchedulerHandler({ PsId: -1 }, event)
                }
              />
            )}
          </div>
        )
      ) : (
        ""
      )}

      {isSpecialScheduleActive == true ? (
        allSpecialScheduler.length > 0 ? (
          <div>
            <div className="card">
              <div className="tableDesign row-eq-height tableDevices ">
                <div className="table-responsive" data-testid="table">
                  <Grid
                    data={allSpecialScheduler}
                    columns={specialScheduleColumns}
                    keyField="PsId"
                    loading={false}
                  />
                </div>
              </div>
            </div>
            {allSpecialScheduler.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <img src={noMessageIcon} alt="" className="no_message" />
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_special_scheduler" />
            </p>
            <p className="mb-3">
              {formatMessage("no_special_scheduler_message")}
            </p>
            {isOperationPermittedRoleBase(ModuleType.PriceSchedular, "Add") && (
              <LoaderButton
                text="add_special_scheduler"
                onClick={(event: any) =>
                  openAddSpecialSchedulerHandler({ SsId: -1 }, event)
                }
              />
            )}
          </div>
        )
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default 
  SignBoardPopupHOC(
    ChooseGroupPopupHOC(
      AddSpecialSchedulerPopupHOC(
        AddSchedulerDigitMappingPopupHOC(
          AddWeekdaySchedulerPopupHOC(
            DeletePopupHOC(observer(WeekdayScheduler))
          )
        )
      )
    )
  );
