/*    
<summary>
   This component is Managing Branch Management & performing CRUD operations on the Branches.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:04-April-2024, Last Updated By: Mohammad Saquib Khan, Updated Date: 05-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { ModuleType } from "../../constants/enums/module-type-enum";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/store-provider";
import { isOperationPermittedRoleBase, showIconsRoleBase } from "../../helpers/action-permission-helper";
import { getIsTenantAccessFromLocalStorage, getTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";
import IPageInfo, { IActionFormatter, IPageLimitInfo } from "../../models/ICommon";
import { IBranchList } from "../../models/response/IBranchResponse";
import LoaderButton from "../../shared-components/button/loader-button";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import {
  CustomActionFormatter
} from "../../shared-components/Grid/GridFormatter";
import Pagination from "../../shared-components/Grid/Pagination";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { formatMessage } from "../../translations/format-message";
import AddUpdateBranch from "../branch/components/add-edit-branch";
import "./downloads.css";

const Downloads = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { downloadsStore, preferencesStore, authStore } = useStore();
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    resetUnwantedModules,
    setTenantAccess,
  } = preferencesStore;
  const { allDownloadsList, GetDownloadsListService, downloadsList ,getAllDownloadsState, inProgress } = downloadsStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const allDownloadedList: Array<any> = allDownloadsList;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllBranch" store funtion that get branch list from API
   */
  const callGetBranchListService = () => {
    GetDownloadsListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      getTenantAccessFromLocalStorage()
    );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetBranchListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: downloadsList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadsList]);

  // useEffect(() => {
  //   if(filter.IsFilterActive)
  //     callGetBranchListService();
  //   else
  //     callGetBranchListService();
  // }, [filter.IsFilterActive]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetBranchListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetBranchListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the branch by providing Id to the Api
   */
  const deleteHandler = () => {
    // DeleteBranchService(selectedId, getTenantAccessFromLocalStorage());
  };

  /**
   * This function shows the delete branch confirmation popup component and sets the seleted branch Id
   */
  const deleteClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.Id);
    event.stopPropagation();
    closePanelhandler();
    // resetGetBranchDetail();
    props.deletePopupToggleHandler();
  };

  /**
   * This function shows the change password popup component and sets the seleted branch Id
   */
  const changePasswordClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.changePasswordToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteBranchState" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (deleteBranchState.success) {
  //     toast.success(formatMessage("deleted_success"));
  //     reset();
  //     props.deletePopupToggleHandler();
  //     if(allDownloadedList.length === 1){
  //       setPageInfo({...pageInfo,currentPage: pageInfo.currentPage > 1 ? pageInfo.currentPage -1 : pageInfo.currentPage, isRefreshPage:true, isPagerChange: true});
  //     }
  //     else
  //       callGetBranchListService();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteBranchState.success]);

    /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
    useEffect(() => {
      if (language !== lang) {
        callGetBranchListService();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetBranchListService();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
  };

  const exitTenantHandler = () => {
    setTenantAccess({
      isAccessTenantActive: false,
      tenantId: 0,
      tenantName: "",
      orgId: "",
    });
    resetUnwantedModules();
    history.push(RoutesConstants.TenantManagement);
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase([
    // {
    //   dataField: "isEdit",
    //   handler: openAddBranchHandler,
    //   icon: (
    //     <svg id="Layer_2"  fill="#0091ff"   width="21"
    //     height="21" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.43 24.75">
       
    //       <g id="_レイヤー_3" data-name="レイヤー 3">
    //         <g>
    //           <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z"/>
    //           <polygon   points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46"/>
    //           <path   d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z"/>
    //           <path  d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z"/>
    //           <rect   x="13.01" y="8.8" width="11.25" height="1.3" transform="translate(-1.22 15.95) rotate(-45)"/>
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    //   isDisable: true,
    //   title: "edit",
    //   action: "edit",
    //   varient: "primary",
    // },
    {
      dataField: "isDelete",
      handler: deleteClickHandler,
      icon: (
        <svg id="Layer_2"  fill="#0091ff"    width="18"
        height="18" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 20.66">
   
        <g id="_レイヤー_3" data-name="レイヤー 3">
          <g>
            <polygon  points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99"/>
            <path  d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z"/>
            <rect  x="11.68" y="6.8" width="1.16" height="9.8"/>
          </g>
        </g>
      </svg>
      ),
      isDisable: true,
      title: "delete",
      action: "delete",
      varient: "danger",
    },
  ], ModuleType.Downloads)

  /**
   * The column constant defines the column description for the branch grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "RequestStartTime",
      text: formatMessage("request_start_time"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "RequestEndTime",
      text: formatMessage("request_end_time"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "FileName",
      text: formatMessage("file_name"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons.length > 0 ? false : true
    },
  ];

  return (
    <React.Fragment>
      {/* {props.showDeletePopup && (
        <DeletePopup
          title="delete_branch"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_branch_confirm"
          isLoading={deleteBranchState.inProgress}
        />
      )} */}

      {inProgress && (
        <FormLoader
          loading={
            inProgress
          }
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-8 col-12" >
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="BranchManagement" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">              
                    <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="BranchManagement" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-4 col-12">
            <ul className="add-list">
              
              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {allDownloadedList.length > 0 ? (
         <div className={`${openPanel ? "panelOpen" : ""}`}>
         <div>
         <div className="card changingPanel">
          <div className="tableDesign row-eq-height tableDevices ">
            <div className="table-responsive" data-testid="table">
              {allDownloadedList && (
                <Grid
                  data={allDownloadedList}
                  columns={columns}
                  keyField="Id"
                  loading={false}
                  rowClasses={rowClassFormat}
                  rowEvents={{ onClick: rowEvents }}
                />
              )}
            </div>
          </div>
          </div>
          {allDownloadedList.length > 0 ? (
            <div className="row no-gutters p-2 tableFooter">
              <div className="col-sm-6 pt-1">
                <FormattedMessage id="show" />
                <select
                  data-testid="pageSelect"
                  className="pageLimit"
                  onChange={onPageLimitChanged}
                  value={pageLimitInfo.pageLimit}
                >
                  {pageLimitOptions.map((op: any) => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <Pagination
                  isRefreshPage={pageInfo.isRefreshPage}
                  totalPages={pageInfo.totalPages}
                  pageLimit={pageLimitInfo.pageLimit}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                  setIsRefreshPage={setIsRefreshPage}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_downloads" />
          </p>
          <p className="mb-3">{formatMessage("no_downloads_message")}</p>
        </div>
      )}

      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10">
              <h2 className="fw-bold mb-0 mt-1 py-2">
                {selectedRow.BranchName}
                {/* <FormattedMessage id={"config_details"} /> */}
              </h2>
            </div>
            <div className="col-lg-2">
              <span title={formatMessage("close")}>            
              <svg 
                onClick={closePanelhandler}
                role="button"
                xlinkTitle="close"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x ms-auto d-block"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            {/* <BranchDetails selectedData={selectedRow} /> */}
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default DeletePopupHOC(observer(Downloads));
